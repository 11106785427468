@import url('https://fonts.googleapis.com/css2?family=Licorice&family=Lobster&family=Merienda+One&family=Montserrat:ital,wght@0,300;0,400;0,700;1,900&display=swap');

:root {
  --background-color: hsl(100, 35%, 90%);
  --highlight-background-color: hsl(336, 80%, 50%);
  --highlight-text-color: #f2ffe9;
  --action-background-color: #557c55;

  --text-color: slategray;

  --body-font: 'Montserrat', sans-serif;
  --header-h1-font: 'Lobster', cursive;
  --header-h2-font: 'Montserrat', sans-serif;
  --quote-font: 'Merienda One', cursive;
  --signature-font: 'Licorice', cursive;
  --message-font: 'Courier New', Courier, monospace;

  --material-padding: 1.5rem;
  --main-spacing: 2.5rem;
  --main-reduced-spacing: 1rem;
}

body {
  background-color: var(--background-color);
  font-family: var(--body-font);
}

.app {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

header,
footer {
  padding: var(--main-reduced-spacing) var(--main-spacing);
  background-color: var(--highlight-background-color);
  color: var(--highlight-text-color);
}

header {
  padding: var(--main-reduced-spacing) var(--main-spacing);
  padding-bottom: calc(var(--main-reduced-spacing) * 1.5);
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

footer {
  flex: 0 0 auto;
  justify-self: flex-end;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  bottom: 0;
}

footer > * {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
  text-align: center;
}

.footer-text {
  font-weight: bold;
}
.footer-text > a {
  font-weight: bold;
}

footer .acknowledge {
  font-size: smaller;
}
footer .acknowledge > a {
  font-weight: bold;
}

header h1 {
  font-family: var(--header-h1-font);
  font-size: 3em;
  line-height: 1;
  white-space: nowrap;
}

header a:hover {
  text-shadow: 0.08em 0.08em black;
}

footer a:hover {
  text-shadow: 0.1em 0.1em black;
}

h2 {
  margin-bottom: var(--material-padding);
  font-family: var(--header-h2-font);
}

main {
  flex: 1 0 auto;
  margin: 0 auto;
  padding: calc(var(--main-spacing) * 1.2) var(--main-spacing);
}

main.welcome,
main.error {
  max-width: 600px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  text-align: center;
  line-height: 1.5;
  white-space: pre-wrap;
}

.build-link {
  display: inline-block;
  margin-top: var(--material-padding);
  font-weight: bold;
}

.build-link:hover {
  color: var(--highlight-background-color);
}

.build-link::before {
  content: '👉';
  margin-right: 0.75em;
}

.build-link::after {
  content: '🍯';
  margin-left: 0.5em;
}

.error > .cardstock {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.error-message button {
  margin-top: 1.75em;
  margin-bottom: 0.75em;
}

.error-message button.concealed::after {
  content: ' 🙈';
}

.error-message button.revealed::after {
  content: ' 🙊';
}

.error-message > p {
  font-size: smaller;
  color: var(--highlight-background-color);
}

.hero-emoji {
  font-size: 3em;
  margin-bottom: 0;
}

main.builder {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(400px, 700px) auto;
  grid-template-areas: 'card toolkit';
  gap: var(--main-spacing);
  align-content: flex-start;
}

main.retriever {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(400px, 700px);
  grid-template-areas: 'card';
  align-content: center;
}

.cardstock {
  margin: var(--main-reduced-spacing);
  padding: var(--material-padding);
  border-radius: 0.2em;
  background-color: white;
  box-shadow: 0.5em 0.75em 0.2em rgb(0, 0, 0);
  transition: all 0.75s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.retriever .cardstock:hover {
  transform: translateY(-0.75em);
  box-shadow: 0.75em 1em 1.25em 0.5em rgba(0, 0, 0, 0.65);
}

button {
  border: 0;
  border-radius: 0.2em;
  background-color: var(--action-background-color);
  color: var(--highlight-text-color);
  box-shadow: 0.15em 0.15em black;
  -webkit-transform: translateY(-0.15em);
  transform: translateY(-0.15em);
  transition: background-color 0.3s ease-out;
}

button.action-button {
  font-weight: bold;
}

header .action-button {
  margin-top: 0.2em;
  height: 2em;
  white-space: nowrap;
}
header .action-button:hover {
  background-color: var(--highlight-text-color);
  color: var(--highlight-background-color);
}
header .pointy-hand {
  font-size: 5em;
  position: absolute;
  right: calc(0.8 * var(--main-spacing));
  bottom: -1.1em;
  line-height: 1.5;
  animation: fadeAndPoint 5s 1s 1 both;
}
@keyframes fadeAndPoint {
  from {
    opacity: 0;
    transform: translateY(30vh);
    text-shadow: none;
  }
  10% {
    opacity: 1;
    transform: translateY(0);
    text-shadow: 0.25rem 0.45rem 0.55rem black;
  }
  21% {
    opacity: 1;
    transform: translateY(0.2em);
    text-shadow: 0.25rem 0.45rem 0.55rem black;
  }
  30% {
    opacity: 1;
    transform: translateY(0);
    text-shadow: 0.25rem 0.45rem 0.55rem black;
  }
  41% {
    opacity: 1;
    transform: translateY(0.2em);
    text-shadow: 0.25rem 0.45rem 0.55rem black;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
    text-shadow: 0.25rem 0.45rem 0.55rem black;
  }
  to {
    opacity: 0;
    transform: translateY(0.5em);
    text-shadow: none;
  }
}

button:active {
  box-shadow: 0.15em 0 black;
  -webkit-transform: translateY(0.15em);
  transform: translateY(0.15em);
}

button.reveal-error {
  font-size: smaller;
  background-color: var(--background-color);
  color: var(--action-background-color);
}

button:hover {
  background-color: var(--highlight-background-color);
}

input,
textarea {
  border: 1px solid var(--action-background-color);
  border-radius: 0.2em;
}

a {
  transition: all 0.2s ease-out;
}

/* Std builder width 1073px */
@media only screen and (max-width: 1073px) {
  :root {
    --material-padding: 1rem;
    --main-spacing: 2rem;
    --main-reduced-spacing: 0.8rem;
  }
  main {
    padding: var(--main-spacing);
  }
}

/* Flip builder to stacked toolkit mode 872px */
@media only screen and (max-width: 872px) {
  main.builder {
    grid-template-rows: auto auto;
    grid-template-columns: minmax(400px, 700px);
    grid-template-areas:
      'card'
      'toolkit';
  }
}

/* Std retriever width 764px */
@media only screen and (max-width: 764px) {
  :root {
    --material-padding: 0.75rem;
    --main-spacing: 1rem;
    --main-reduced-spacing: 0.4rem;
  }
  header .action-button {
    height: 2.5em;
  }
  body {
    font-size: 0.9rem;
  }
  main.builder {
    grid-template-columns: auto;
  }
  main.retriever {
    grid-template-columns: auto;
  }
}

/* Resize card container to medium 580px */
@media only screen and (max-width: 580px) {
  body {
    font-size: 0.75rem;
  }
}

/* Resize fonts to min 425px */
@media only screen and (max-width: 425px) {
  body {
    font-size: 0.6rem;
  }
}
