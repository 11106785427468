.toolkit {
  margin: 0;
  grid-area: toolkit;
  place-self: start center;

  display: grid;
  grid-template-columns: [start] repeat(3, auto) [end];
  grid-template-rows: auto;
  place-items: center;
}

.toolkit > * {
  margin: 0.5em 0;
}
.toolkit > *:first-child {
  margin-top: 0;
}
.toolkit > *:last-child {
  margin-bottom: 0;
}

.toolkit > button {
  place-items: stretch;
  height: 2em;
  width: 2em;
  text-align: center;
  padding: 0;
}

.toolkit > .prev,
.toolkit > .next {
  justify-self: center;
}

.toolkit > .wide {
  width: auto;
  grid-column: start / end;
  place-self: center stretch;
  white-space: nowrap;
}

.toolkit > label.wide {
  text-align: center;
  margin-bottom: -0.25em;
}

.toolkit > input::placeholder,
.toolkit > textarea::placeholder {
  text-align: center;
}

.toolkit > .text-button {
  width: auto;
  border: none;
  background-color: transparent;
  color: inherit;
  font: inherit;
  white-space: nowrap;
  box-shadow: none;
}

#message {
  resize: vertical;
  white-space: pre-line;
}

.toolkit > .prev::before {
  content: '\25C0\FE0E';
}

.toolkit > .next::before {
  content: '\25B6\FE0E';
}
