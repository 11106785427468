.card {
  min-height: 350px;
  max-width: 900px;
  margin: 0;
  padding: 0;

  grid-area: card;
  place-self: start center;

  display: grid;
  place-items: stretch;
  grid-template-columns: minmax(300px, 1fr) minmax(200px, 1fr);
  grid-template-rows: minmax(150px, 1fr) auto auto;
  grid-template-areas:
    'left tr'
    'left br'
    'left bttm';
}

.card * {
  max-width: 100%;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.note {
  grid-area: br;
  place-self: end end;

  font-family: var(--message-font);
  font-size: 1.2em;
}

.photo,
.quote,
.recipient,
.message {
  margin: var(--material-padding);
}

.signature {
  grid-area: bttm;
  place-self: end end;

  font-family: var(--signature-font);
  line-height: 1;
  font-size: 1.5em;
  text-align: right;
}

.signature > p {
  margin: calc(var(--material-padding) * -0.5) var(--main-spacing)
    var(--main-reduced-spacing) var(--material-padding);
}

.sender {
  font-size: 1.5em;
  font-weight: 500;
}

.sender::before {
  /* Insert line break */
  content: '\A';
}

.quote {
  grid-area: tr;
  place-self: center stretch;

  font-family: var(--quote-font);
  font-size: 1.25em;

  quotes: '“' '”';
}

.quote-text::before {
  content: open-quote;
}
.quote-text::after {
  content: close-quote;
}

.author {
  text-align: right;
}
.author::before {
  content: '~ ';
}

.photo {
  grid-area: left;
  /* Use black mat? */
  /* background-color: black; */
  -o-object-fit: cover;
  object-fit: cover;
}

/* Std builder width 1073px */
@media only screen and (max-width: 1073px) {
  .photo {
    margin-right: 0;
  }
}

/* Resize card container to medium 580px */
@media only screen and (max-width: 580px) {
  .card {
    min-height: 250px;
    grid-template-columns: minmax(150px, 1fr) minmax(100px, 1fr);
    grid-template-rows: auto auto auto;
  }
}
